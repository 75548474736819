class CollapseHandler {
    constructor(inputCollapsesWrapper) {
        this.inputCollapsesWrapper = inputCollapsesWrapper;
        this.lastOpened = null;
        this.inputCollapseCollection = this.inputCollapsesWrapper.querySelectorAll('[data-bs-toggle="collapse"]');
        this.defaultInput = Array.from(this.inputCollapseCollection).find(input => input.checked);

        if (this.defaultInput) {
            const targetId = this.defaultInput.getAttribute('data-bs-target');
            const targetElement = inputCollapsesWrapper.querySelector(targetId);
            this.lastOpened = targetElement;
        }
    }

    handleShowCollapse(event) {
        const target = event.target;

        if (this.lastOpened && this.lastOpened !== target) {
            $(this.lastOpened).collapse('hide');
        }

        this.lastOpened = target;
    }

    handleHideCollapse(event) {
        if (event.target === this.lastOpened && this.inputCollapsesWrapper.querySelectorAll('.collapse.show').length === 1) {
            event.preventDefault();
        }
    }

    initialize() {
        this.inputCollapsesWrapper.querySelectorAll('.collapse').forEach(collapseElement => {
            collapseElement.addEventListener('show.bs.collapse', this.handleShowCollapse.bind(this));
            collapseElement.addEventListener('hide.bs.collapse', this.handleHideCollapse.bind(this));
        });
    }
}

module.exports = function () {
    const inputCollapsesWrapperCollection = document.querySelectorAll('[data-bs-custom="collapse"]');

    inputCollapsesWrapperCollection.forEach((inputCollapsesWrapper) => {
        const collapseHandler = new CollapseHandler(inputCollapsesWrapper);
        collapseHandler.initialize();
    });
};
