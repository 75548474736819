'use strict';

var formValidation = require('../components/formValidation');
var createErrorNotification = require('../components/errorNotification');
const datalayer = require('../components/datalayer');

/**
 * Submits the login form to the server and handles the response.
 *
 * @param {Element} loginForm - The login form element to submit.
 * @param {string} token - The token to include in the form data (optional).
 * @return {Promise} A promise that resolves with the response data if the request is successful.
 *                   Otherwise, it rejects with the response object.
 */
function loginFormSubmit(loginForm) {
    const url = loginForm.getAttribute('action');
    let params = new FormData(loginForm);
    fetch(url, {
        method: 'post',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams(params)
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
        return Promise.reject(res);
    }).then(data => {
        $(loginForm).spinner().stop();
        if (!data.success) {
            formValidation(loginForm, data);
            loginForm.dispatchEvent(new CustomEvent('login:error', { detail: data }));
        } else {
            loginForm.dispatchEvent(new CustomEvent('login:success', { detail: data }));
            window.location.href = data.redirectUrl;
        }
    }).catch(err => {
        if (err.responseJSON.redirectUrl) {
            window.location.href = err.responseJSON.redirectUrl;
        } else {
            loginForm.dispatchEvent(new CustomEvent('login:error', { detail: err }));
            $(loginForm).spinner().stop();
        }
    });
    return false;
}

function resetPasswordFormSubmit(resetPasswordForm) {
    const url = resetPasswordForm.getAttribute('action');
    let params = new FormData(resetPasswordForm);
    fetch(url, {
        method: 'post',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams(params)
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
        return Promise.reject(res);
    }).then(data => {
        $(resetPasswordForm).spinner().stop();
        if (!data.success) {
            formValidation(resetPasswordForm, data);
            resetPasswordForm.dispatchEvent(new CustomEvent('login:reset:error', { detail: data }));
        } else {
            resetPasswordForm.dispatchEvent(new CustomEvent('login:reset:success', { detail: data }));
            $('.request-password-title').text(data.receivedMsgHeading);
            $('.request-password-body').empty()
                .append('<p>' + data.receivedMsgBody + '</p>');
            if (!data.mobile) {
                $('#submitEmailButton').text(data.buttonText)
                    .attr('data-bs-dismiss', 'modal');
            } else {
                $('.send-email-btn').empty()
                    .html('<a href="'
                        + data.returnUrl
                        + '" class="btn btn-primary btn-block">'
                        + data.buttonText + '</a>');
            }
        }
    }).catch(err => {
        if (err.responseJSON.redirectUrl) {
            window.location.href = err.responseJSON.redirectUrl;
        } else {
            resetPasswordForm.dispatchEvent(new CustomEvent('login:reset:error', { detail: err }));
            $(resetPasswordForm).spinner().stop();
        }
    });
    return false;
}

function registerFormSubmit(registerForm) {
    const url = registerForm.getAttribute('action');
    let params = new FormData(registerForm);
    fetch(url, {
        method: 'post',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams(params)
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
        return Promise.reject(res);
    }).then(data => {
        $(registerForm).spinner().stop();
        if (!data.success) {
            registerForm.dispatchEvent(new CustomEvent('login:register:error', { detail: data }));
            formValidation(registerForm, data);
        } else {
            registerForm.dispatchEvent(new CustomEvent('login:register:success', { detail: data }));
            const urlParams = new URLSearchParams(url.split('?')[1]);
            const rurlValue = urlParams.get('rurl');

            if (rurlValue) {
                let gtmTriggerLocation = rurlValue === '1' ? 'account_registration' : 'checkout_registration';

                if (window.dataTrackingView?.page_info?.pageType === 'wishlist') {
                    gtmTriggerLocation = 'wishlist';
                }

                datalayer.accountRegistrationTracking(gtmTriggerLocation);

                if (data.addToNewsletter) {
                    datalayer.newsletterSubscriptionTracking('subscribe', gtmTriggerLocation);
                }
            }

            window.location.href = data.redirectUrl;
        }
    }).catch(err => {
        if (err.responseJSON.redirectUrl) {
            window.location.href = err.responseJSON.redirectUrl;
        } else {
            createErrorNotification(document.querySelector('.error-messaging'), err.responseJSON.errorMessage);
        }
        $(registerForm).spinner().stop();
    });
    return false;
}

module.exports = {
    login: function () {
        const form = document.querySelector('form.login');
        if (form) {
            form.addEventListener('submit', function (e) {
                e.preventDefault();
                e.stopImmediatePropagation();
                form.dispatchEvent(new CustomEvent('login:submit', { detail: e }));
                let renderCaptchaElement = form.querySelector('.g-recaptcha');
                if (renderCaptchaElement) {
                    // eslint-disable-next-line no-undef
                    grecaptcha.ready(function () {
                        const siteKey = renderCaptchaElement.dataset.sitekey;
                        // eslint-disable-next-line no-undef
                        grecaptcha.execute(siteKey, { action: 'login' }).then(function (token) {
                            form.querySelector('input[name="responseToken"]').value = token;
                            $(form).spinner().start();
                            loginFormSubmit(form);
                        });
                    });
                } else {
                    $(form).spinner().start();
                    loginFormSubmit(form);
                }
            });
        }
    },

    register: function () {
        const form = document.querySelector('form.registration');
        if (form) {
            form.addEventListener('submit', function (e) {
                e.preventDefault();
                e.stopImmediatePropagation();
                form.dispatchEvent(new CustomEvent('login:register', { detail: e }));
                let renderCaptchaElement = form.querySelector('.g-recaptcha');
                if (renderCaptchaElement) {
                    // eslint-disable-next-line no-undef
                    grecaptcha.ready(function () {
                        const siteKey = renderCaptchaElement.dataset.sitekey;
                        // eslint-disable-next-line no-undef
                        grecaptcha.execute(siteKey, { action: 'register' }).then(function (token) {
                            form.querySelector('input[name="responseToken"]').value = token;
                            $(form).spinner().start();
                            registerFormSubmit(form);
                        });
                    });
                } else {
                    $(form).spinner().start();
                    registerFormSubmit(form);
                }
            });
        }
    },

    resetPassword: function () {
        const form = document.querySelector('.reset-password-form');
        if (form) {
            form.addEventListener('submit', function (e) {
                e.preventDefault();
                e.stopImmediatePropagation();
                form.dispatchEvent(new CustomEvent('login:reset-password', { detail: e }));
                let renderCaptchaElement = form.querySelector('.g-recaptcha');
                if (renderCaptchaElement) {
                    // eslint-disable-next-line no-undef
                    grecaptcha.ready(function () {
                        const siteKey = renderCaptchaElement.dataset.sitekey;
                        // eslint-disable-next-line no-undef
                        grecaptcha.execute(siteKey, { action: 'resetPassword' }).then(function (token) {
                            form.querySelector('input[name="responseToken"]').value = token;
                            $(form).spinner().start();
                            resetPasswordFormSubmit(form);
                        });
                    });
                } else {
                    $(form).spinner().start();
                    resetPasswordFormSubmit(form);
                }
            });
        }
    },

    clearResetForm: function () {
        $('#login .modal').on('hidden.bs.modal', function () {
            $('#reset-password-email').val('');
            $('.modal-dialog .form-control.is-invalid').removeClass('is-invalid');
        });
    }
};
