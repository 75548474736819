'use strict';

var processInclude = require('./util');
const materialIcon = require('./materialIcon');

$(document).ready(function () {
    processInclude(require('./login/login'));
    processInclude(require('./thirdParty/bootstrapCollapseCustomization'));

    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get('success') === 'true') {
        const feedbackElement = $('.feedback-element');
        let message = document.querySelector('.js-login').getAttribute('data-success-message');
        if (feedbackElement) {
            feedbackElement.append(`<div class="toast-message password-success-message">
                                        <div class="toast-alert alert password-success-alert alert-success px-0">
                                            <div class="container">
                                                <span class="svg-wrapper-inherit me-3 material-icon-wrapper-small lh-1">${materialIcon.check}</span>
                                                ${message}
                                            </div>
                                        </div>
                                    </div>`);
        }
    }

    setTimeout(function () {
        document.querySelector('.password-success-message')?.remove();
    }, 3000);
});
